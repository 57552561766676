<script setup lang="ts">
import { useDisplay } from "vuetify";
import { VSlideYTransition } from "vuetify/components";

const { lgAndUp, mdAndUp } = useDisplay();

const NavigationMobile = defineAsyncComponent(() => import("@/components/navigation/mobile.vue"));
const ContactPanel = defineAsyncComponent(() => import("@/components/contact/panel.vue"));

const isMenuShow = ref(false);
const isContactShow = ref(false);

// eslint-disable-next-line no-console
// if (process.env.NODE_ENV !== "development") console.log("env test: ", process.env);

watch(lgAndUp, () => lgAndUp.value && (isMenuShow.value = false));
watch(isMenuShow, () => {
    const htmlEl: HTMLElement | null = document.querySelector("html");
    if (!htmlEl) return;
    htmlEl.style.overflow = isMenuShow.value ? "hidden" : "auto";
});

const isBtnShow = ref(false);

function scrollTop() {
    window.scrollTo({ top: 0 });
}

function handleScroll() {
    isBtnShow.value = window.scrollY > 400;
}

onMounted(() => window.addEventListener("scroll", handleScroll));
onBeforeUnmount(() => window.removeEventListener("click", handleScroll));
</script>

<template>
    <VLayout>
        <VNavigationDrawer v-model="isMenuShow" scrim disable-resize-watcher width="400" style="position: fixed; height: 100vmax">
            <NavigationMobile v-model="isMenuShow" />
        </VNavigationDrawer>

        <AppHeader v-model:isMenuShow="isMenuShow" v-model:isContactShow="isContactShow" />

        <ContactPanel v-model="isContactShow" />

        <VMain>
            <slot name="header"></slot>
            <slot></slot>
        </VMain>

        <VSlideYTransition>
            <VBtn
                v-if="isBtnShow"
                :size="mdAndUp ? 'default' : 'small'"
                color="linear-gradient(red, blue)"
                icon="$up"
                theme="dark"
                variant="flat"
                class="linear-gradient-btn"
                @click="scrollTop"
            ></VBtn>
        </VSlideYTransition>
        <FooterSection />
    </VLayout>
</template>

<style lang="scss" scoped>
.v-navigation-drawer {
    height: 100vh !important;
    overflow-y: auto;

    &__scrim {
        position: fixed !important;
    }
}

main {
    background: url("/img/light-bg.jpg") no-repeat center;
    background-size: cover;
    background-attachment: fixed;

    @supports (gap: 1px) {
        background-image: url("/img/light-bg.webp");
    }
}

[type="button"] {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1000;
}
</style>
