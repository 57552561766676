<script setup lang="ts">
import { useDisplay, useTheme } from "vuetify";
const { themes } = useTheme();
const { medica } = themes.value.customTheme.colors;

defineProps({
    isMenuShow: Boolean,
    isContactShow: Boolean
});
defineEmits(["update:isMenuShow", "update:isContactShow"]);

const NavigationBasic = defineAsyncComponent(() => import("@/components/navigation/basic.vue"));

const { sm, md, lgAndUp } = useDisplay();
const height = computed(() => (sm.value ? 60 : md.value ? 80 : lgAndUp.value ? 104 : 46));
</script>

<template>
    <VAppBar :height="height" color="white" class="px-5 app-bar" scroll-behavior="hide" scroll-threshold="10" style="position: fixed; z-index: 904">
        <VRow no-gutters align="center" justify="space-between" class="fill-height app-bar-wrapper" style="margin: 0 auto">
            <VCol cols="auto" class="d-md-none">
                <VBtn icon="$menu" variant="text" density="compact" @click="$emit('update:isMenuShow', !isMenuShow)"></VBtn>
            </VCol>
            <VCol cols="auto">
                <NuxtLink to="/">
                    <Svg name="allmedica-logo-horizontal" width="100%" height="100%" class="app-header-logo" :color="medica" role="link"></Svg>
                </NuxtLink>
            </VCol>
            <VCol class="fill-height d-none d-md-block">
                <NavigationBasic :model-value="isMenuShow" @update:model-value="$emit('update:isMenuShow', !isMenuShow)" />
            </VCol>
            <VCol cols="auto">
                <VBtn icon="$phone" variant="text" density="compact" @click="$emit('update:isContactShow', !isContactShow)"></VBtn>
            </VCol>
        </VRow>
    </VAppBar>
</template>

<style lang="scss" scoped>
.app-bar-wrapper {
    width: 93%;

    .app-header-logo {
        height: 100% !important;
        width: 7.5rem !important;

        @media (width > 600px) {
            width: 13.5rem !important;
        }
    }

    @media (width > 960px) {
        max-width: 55rem;
    }

    @media (width > 1280px) {
        max-width: 72rem;
    }

    @media (width > 1920px) {
        max-width: 83rem;
    }
}

:deep(.v-toolbar__content) {
    height: 46px !important;

    @media (width > 600px) {
        height: 60px !important;
    }

    @media (width > 960px) {
        height: 80px !important;
    }

    @media (width > 1280px) {
        height: 104px !important;
    }
}
</style>
<style>
.v-layout > main.v-main {
    padding-top: 46px !important;

    @media (width > 600px) {
        padding-top: 60px !important;
    }
    @media (width > 960px) {
        padding-top: 80px !important;
    }

    @media (width > 1280px) {
        padding-top: 104px !important;
    }
}
</style>
